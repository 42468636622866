<template>
  <div class="" :class="{ 'table-responsive': $isMobile() }">
    <table
      class="table table-borderless table-hover mb-0"
      :class="{ 'table-layout-fixed': !$isMobile() }"
    >
      <thead class="table-info">
        <tr>
          <th class="text-capitalize text-center" width="100">
            {{
              $t(
                "BaseModelFields.SerialNo",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-capitalize">
            {{
              $t(
                "ImportBulkRecordData.ExcelField",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-capitalize">
            {{
              $t(
                "ImportBulkRecordData.ObjectField",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th width="40">&nbsp;</th>
          <th class="text-capitalize">
            {{
              $t(
                "ImportBulkRecordData.ReferenceType",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-capitalize">
            {{
              $t(
                "ImportBulkRecordData.ReferenceField",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-capitalize bg-warning text-center" width="100">
            {{
              $t(
                "Buttons.Actions",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in rows"
          :key="i"
          class="border-bottom-dashed-info"
        >
          <td class="align-middle text-center">
            <span class="fw-bold">
              {{ item.index }}
            </span>
          </td>
          <td>
            <FormSelect
              ref="recordExcelField"
              type="SelectWithLocalData"
              :id="`ms_${item.rId}`"
              @onChange="onChangeExcelFieldForRecord"
              :selectedData="item.excelField ? [item.excelField] : []"
              :data="excelFields"
              :allowEmpty="item.field.key === 'PUBLICID' ? false : true"
              openDirection="top"
            />
          </td>
          <td>
            <FormSelect
              type="SelectWithLocalData"
              :id="`ms_${item.rId}`"
              @onChange="onChangeObjectFieldForRecord"
              :selectedData="[item.field]"
              :data="item.field.isRequired ? [item.field] : objectFields"
              :allowEmpty="false"
              :isDisabled="item.field.isRequired"
              openDirection="top"
            />
          </td>
          <td class="align-middle">
            <i
              class="bi bi-asterisk text-danger"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="
                this.$t(
                  'BaseModelFields.RequiredField',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
              v-if="item.field.isRequired"
            ></i>
          </td>
          <td>
            <template v-if="item.field.fieldType == 'Lookup'">
              <FormSelect
                type="SelectWithLocalData"
                :id="`ms_${item.rId}`"
                @onChange="onChangeReferenceTypeForRecord"
                :data="referenceTypesForLookup"
                :selectedData="item.referenceType ? [item.referenceType] : []"
                :allowEmpty="false"
                openDirection="top"
              />
            </template>
            <template
              v-else-if="
                item.field.fieldType == 'OrganizationalUnit' ||
                item.field.fieldType == 'SelectList' ||
                item.field.fieldType == 'Predefined' ||
                item.field.fieldType == 'Image' ||
                item.field.fieldType == 'File'
              "
            >
              <FormSelect
                type="SelectWithLocalData"
                :id="`ms_${item.rId}`"
                @onChange="onChangeReferenceTypeForRecord"
                :data="referenceTypesOther"
                :selectedData="item.referenceType ? [item.referenceType] : []"
                :allowEmpty="false"
                openDirection="top"
              />
            </template>
          </td>
          <td>
            <template
              v-if="
                item.field.fieldType == 'Lookup' &&
                item.referenceType &&
                item.referenceType.key == 4
              "
            >
              <FormSelect
                type="SelectWithLocalData"
                :id="`ms_${item.rId}`"
                @onChange="onChangeReferenceFieldForRecord"
                :data="item.referenceFields"
                :selectedData="item.referenceField ? [item.referenceField] : []"
                :allowEmpty="false"
                openDirection="top"
              />
            </template>
          </td>
          <td class="align-middle text-center">
            <button
              v-if="item.isDeleteButtonActive"
              @click="deleteRow(item)"
              type="button"
              class="btn btn-danger btn-sm btn-no-custom-style"
            >
              <i class="bi bi-trash align-middle me-0 fs-4"></i>
            </button>
          </td>
        </tr>

        <tr>
          <td class="align-middle text-center">
            <span class="fw-bold">{{ rows.length + 1 }}</span>
          </td>
          <td>
            <FormSelect
              type="SelectWithLocalData"
              @onChange="onChangeExcelField"
              :resetSelect="resetSelect"
              :data="excelFields"
              openDirection="top"
            />
          </td>
          <td>
            <FormSelect
              type="SelectWithLocalData"
              @onChange="onChangeField"
              :data="objectFields"
              :resetSelect="resetSelect"
              openDirection="top"
            />
          </td>
          <td class="align-middle"></td>
          <td>
            <template
              v-if="selectedField && selectedField.fieldType == 'Lookup'"
            >
              <FormSelect
                type="SelectWithLocalData"
                @onChange="onChangeReferenceType"
                :data="referenceTypesForLookup"
                openDirection="top"
              />
            </template>
            <template
              v-else-if="
                selectedField &&
                (selectedField.fieldType == 'OrganizationalUnit' ||
                  selectedField.fieldType == 'SelectList' ||
                  selectedField.fieldType == 'Predefined' ||
                  selectedField.fieldType == 'Image' ||
                  selectedField.fieldType == 'File')
              "
            >
              <FormSelect
                type="SelectWithLocalData"
                @onChange="onChangeReferenceType"
                :data="referenceTypesOther"
                openDirection="top"
              />
            </template>
          </td>
          <td>
            <template
              v-if="
                selectedField &&
                selectedField.fieldType == 'Lookup' &&
                selectedReferenceType &&
                selectedReferenceType.key == 4
              "
            >
              <FormSelect
                type="SelectWithLocalData"
                @onChange="onChangeReferenceField"
                :data="referenceFields"
                openDirection="top"
              />
            </template>
          </td>
          <td class="align-middle text-center">
            <button
              @click="addRow"
              type="button"
              class="btn btn-success btn-sm btn-no-custom-style"
            >
              <i class="bi bi-plus align-middle me-0 fs-4"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
export default {
  name: "DataImportMappingTable",
  props: [
    "excelFields",
    "objectFields",
    "customObjectAllFields",
    "customObjectMappingFields",
  ],
  emits: ["exportMapping"],
  components: {},
  data() {
    return {
      referenceTypesForLookup: [
        {
          key: 3,
          value: "Record Public Id",
        },
        {
          key: 4,
          value: this.$t(
            "ImportBulkRecordData.ReferenceTypeForField",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      referenceTypesOther: [],
      rows: this.customObjectMappingFields,
      selectedExcelField: null,
      selectedField: null,
      selectedReferenceType: null,
      selectedReferenceField: null,
      referenceFields: [],
      resetSelect: false,
    };
  },
  mounted() {
    if (this.rows.length > 0) {
      this.modifyMappingsAndParentNotify();
    }
  },
  methods: {
    onChangeReferenceType(selected) {
      this.referenceFields = [];
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.selectedReferenceType = null;
      } else {
        this.selectedReferenceType = selected;
        if (
          this.selectedField.fieldType == "Lookup" &&
          this.selectedReferenceType.key == 4
        ) {
          this.getReferenceFields(this.selectedField.lookupObjectPublicId);
        }
      }
    },
    onChangeReferenceField(selected) {
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.selectedReferenceField = null;
      } else {
        this.selectedReferenceField = selected;
      }
    },
    onChangeExcelField(selected) {
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.selectedExcelField = null;
      } else {
        this.selectedExcelField = selected;
      }
    },
    onChangeField(selected) {
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.selectedField = null;
      } else {
        this.selectedField = selected;
        if (
          this.selectedField.fieldType === "Predefined" ||
          this.selectedField.fieldType === "OrganizationalUnit"
        ) {
          this.referenceTypesOther = [
            {
              key: 1,
              value: "Public Id",
            },
          ];
        } else {
          this.referenceTypesOther = [
            {
              key: 1,
              value: "Public Id",
            },
            {
              key: 2,
              value: this.$t(
                "ImportBulkRecordData.ReferenceTypeForName",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          ];
        }
      }
    },
    returnRecordId(msId) {
      return msId.split("_")[1];
    },
    onChangeExcelFieldForRecord(selected, msId) {
      var recordId = this.returnRecordId(msId),
        record = this.rows.find((f) => f.rId == recordId);

      if (!String.isNullOrWhiteSpace(record)) {
        record.excelField = {};

        if (!String.isNullOrWhiteSpace(selected.key)) {
          record.excelField = {
            key: selected.key,
            value: selected.value,
          };
        }
      }

      this.modifyMappingsAndParentNotify();
    },
    onChangeObjectFieldForRecord(selected, msId) {
      var recordId = this.returnRecordId(msId),
        record = this.rows.find((f) => f.rId == recordId);

      if (!String.isNullOrWhiteSpace(record)) {
        record.field = {};
        record.referenceType = null;
        record.referenceField = null;
        record.referenceFields = [];

        if (!String.isNullOrWhiteSpace(selected.key)) {
          record.field = {
            key: selected.key,
            value: selected.value,
            fieldType: selected.fieldType,
            organizationalUnitName: selected.organizationalUnitName,
            lookupObjectPublicId: selected.lookupObjectPublicId,
            isRequired: selected.isRequired,
            formulaName: selected.formulaName,
          };
        }
      }
      this.modifyMappingsAndParentNotify();
    },
    onChangeReferenceTypeForRecord(selected, msId) {
      var recordId = this.returnRecordId(msId),
        record = this.rows.find((f) => f.rId == recordId);

      if (!String.isNullOrWhiteSpace(record)) {
        record.referenceType = {};
        record.referenceField = {};
        record.referenceFields = [];

        if (!String.isNullOrWhiteSpace(selected.key)) {
          record.referenceType = {
            key: selected.key,
            value: selected.value,
          };

          if (
            record.field.fieldType == "Lookup" &&
            record.referenceType.key == 4
          ) {
            this.getReferenceFields(record.field.lookupObjectPublicId, record);
          }
        }
      }

      this.modifyMappingsAndParentNotify();
    },
    onChangeReferenceFieldForRecord(selected, msId) {
      var recordId = this.returnRecordId(msId),
        record = this.rows.find((f) => f.rId == recordId);

      if (!String.isNullOrWhiteSpace(record)) {
        record.referenceField = {};

        if (!String.isNullOrWhiteSpace(selected.key)) {
          record.referenceField = {
            key: selected.key,
            value: selected.value,
            fieldType: selected.fieldType,
            organizationalUnitName: selected.organizationalUnitName,
            lookupObjectPublicId: selected.lookupObjectPublicId,
            isRequired: selected.isRequired,
            formulaName: selected.formulaName,
          };
        }
      }

      this.modifyMappingsAndParentNotify();
    },
    deleteRow(item) {
      this.rows = this.rows.filter(function (f) {
        return f.rId !== item.rId;
      });

      this.modifyMappingsAndParentNotify();
    },
    addRow() {
      if (
        String.isNullOrWhiteSpace(this.selectedExcelField) ||
        String.isNullOrWhiteSpace(this.selectedField)
      ) {
        createToast(
          this.$t(
            "FieldErrors.FillInTheRequiredFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
          }
        );
        return;
      } else if (
        this.selectedField.fieldType == "Lookup" ||
        this.selectedField.fieldType == "OrganizationalUnit" ||
        this.selectedField.fieldType == "SelectList" ||
        this.selectedField.fieldType == "Predefined" ||
        this.selectedField.fieldType == "Image" ||
        this.selectedField.fieldType == "File"
      ) {
        if (String.isNullOrWhiteSpace(this.selectedReferenceType)) {
          createToast(
            this.$t(
              "ImportBulkRecordData.PleaseSelectReferenceType",
              {},
              { locale: this.$store.state.activeLang }
            ),
            {
              showIcon: "true",
              position: "top-right",
              type: "warning",
              transition: "zoom",
            }
          );
          return;
        } else if (
          this.selectedField.fieldType == "Lookup" &&
          this.selectedReferenceType.key == 4 &&
          String.isNullOrWhiteSpace(this.selectedReferenceField)
        ) {
          createToast(
            this.$t(
              "ImportBulkRecordData.PleaseSelectReferenceField",
              {},
              { locale: this.$store.state.activeLang }
            ),
            {
              showIcon: "true",
              position: "top-right",
              type: "warning",
              transition: "zoom",
            }
          );
          return;
        }
      }

      var nextRowIndex = this.rows.length + 1;
      var rowObj = {
        rId: String.newGuid(),
        index: nextRowIndex,
        field: this.selectedField,
        excelField: this.selectedExcelField,
        referenceType: this.selectedReferenceType,
        referenceFields: this.referenceFields,
        referenceField: this.selectedReferenceField,
        isDeleteButtonActive: true,
      };

      var valid = true,
        isRequiredRowNull = false;
      this.rows.forEach((v) => {
        if (
          String.isNullOrWhiteSpace(rowObj.field) ||
          String.isNullOrWhiteSpace(rowObj.excelField) ||
          String.isNullOrWhiteSpace(v.field) ||
          String.isNullOrWhiteSpace(v.excelField)
        ) {
          isRequiredRowNull = true;
          return;
        }
        if (
          rowObj.field.key === v.field.key ||
          rowObj.excelField.key === v.excelField.key
        ) {
          valid = false;
          return;
        }
      });
      // if (isRequiredRowNull) {
      //   createToast(
      //     this.$t(
      //       "FieldErrors.FillInTheRequiredFields",
      //       {},
      //       { locale: this.$store.state.activeLang }
      //     ),
      //     {
      //       showIcon: true,
      //       position: "top-right",
      //       type: "danger",
      //       transition: "zoom",
      //     }
      //   );
      //   return;
      // } else
      if (!valid) {
        createToast(
          this.$t(
            "ImportBulkRecordData.NoUnique",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.resetSelect = !this.resetSelect;
      this.rows.push(rowObj);
      this.modifyMappingsAndParentNotify();

      //configuration default values
      this.selectedField = null;
      this.selectedExcelField = null;
      this.selectedReferenceType = null;
      this.referenceFields = null;
      this.selectedReferenceField = null;

      setTimeout(() => {
        const scrollingElement = document.scrollingElement || document.body;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      }, 100);
    },
    modifyMappingsAndParentNotify() {
      var mappingModel = [],
        rowIndex = 0;
      this.rows.forEach((map) => {
        var skipElement =
          String.isNullOrWhiteSpace(map.excelField) ||
          String.isNullOrWhiteSpace(map.field) ||
          (!String.isNullOrWhiteSpace(map.excelField) &&
            String.isNullOrWhiteSpace(map.excelField.key)) ||
          (!String.isNullOrWhiteSpace(map.field) &&
            String.isNullOrWhiteSpace(map.field.formulaName));
        if (skipElement) return;

        mappingModel.push({
          columnHeaderIndex: String.isNullOrWhiteSpace(map.excelField)
            ? null
            : map.excelField.key,
          columnHeaderText: String.isNullOrWhiteSpace(map.excelField)
            ? null
            : map.excelField.value,
          fieldPublicId: map.field.key,
          fieldFormulaName: map.field.formulaName,
          fieldName: map.field.value,
          fieldMapTypeId: String.isNullOrWhiteSpace(map.referenceType)
            ? null
            : map.referenceType.key,
          fieldMapTypeName: String.isNullOrWhiteSpace(map.referenceType)
            ? null
            : map.referenceType.value,
          referenceFieldPublicId: String.isNullOrWhiteSpace(map.referenceField)
            ? null
            : map.referenceField.key,
          referenceFieldFormulaName: String.isNullOrWhiteSpace(
            map.referenceField
          )
            ? null
            : map.referenceField.formulaName,
          referenceFieldName: String.isNullOrWhiteSpace(map.referenceField)
            ? null
            : map.referenceField.value,
        });
      });

      this.$emit("exportMapping", mappingModel);
    },
    getReferenceFields(lookupObjectPublicId, record = null) {
      var isUpdateRecord = !String.isNullOrWhiteSpace(record);
      if (isUpdateRecord) {
        record.referenceFields = [];
      } else {
        this.referenceFields = [];
      }

      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-FieldList?customObjectPublicId={0}",
            lookupObjectPublicId
          )
        )
        .then((response) => {
          //fields filter after mapping
          var mappingFields = response.data.items.map(function (field) {
            return {
              key: field.publicId,
              value: field.name,
              fieldType: field.fieldType,
              organizationalUnitName: field.organizationalUnitName,
              lookupObjectPublicId: field.lookupObjectPublicId,
              isRequired: field.isRequired,
              formulaName: field.formulaName,
            };
          });

          if (isUpdateRecord) {
            record.referenceFields = mappingFields;
          } else {
            this.referenceFields = mappingFields;
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
};
</script>
